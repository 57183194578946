#titleBar{
    width:100vw;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color:black;
    background-color:rgb(175, 175, 175);
    font-size: calc(1vw + 1vh);
    height:20px;
    overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: nowrap;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
  line-height: 1em; /* a */
 
}