
#splashScreen{
    position:absolute;
    top:0;
    right:0;
    width:100vw;
    height:100vh;
    background-color: black;
    transition: opacity 1s;
    z-index:300;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:white;
    text-align: center;
    pointer-events:none;
}
img{
    width:200px;
}
.title{
    opacity:0;
 font-family: Georgia, 'Times New Roman', Times, serif;
 font-size:50px;
 transition: opacity 1s;
}
.version{
    margin-top:20px;
    margin-bottom:20px;
    font-size: small;
    font-family: 'Courier New', Courier, monospace;
}
.copy{
    font-size: x-small;
}