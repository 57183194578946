#svgContainer{
    position:relative;
    display:block;
    width:100vw;
    height:calc(100vh - 144px);
    overflow-x:scroll;
    overflow-y:scroll;
    opacity:0;
    z-index:10;
    transition:opacity 1s;
}
