div.bodyContainer{
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(82, 82, 82);
}
div.refreshModal,div.errorModal{
    position:fixed;
    top:0;
    left:0;
    color:white;
}
div.errorModal{
width:100vw;
height:100vh;
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
div.Error{
    color:red;
}

div.refreshModal>div:first-child{
   width:100%;
   word-wrap: break-word;
   margin-bottom:10px;
}
div.refreshModal>div{
    width:80%;
    word-wrap: break-word;
    text-align:left;
 }
div.homeHeader,div.homeFooter{
    width:100vw;
    height:60px;
    display:flex;
    flex-direction: row;
    align-items: center;
    
}
div.homeHeader{
    background-color: black;
    color:white;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:30px;
    font-weight:bold;
    justify-content: space-between;
}
div.homeFooter{
    background-color: rgb(45, 45, 45);
    color:white;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:16px;
    justify-content: space-around;
}
div.homeFooter>a{
    color:white;
    text-decoration: none;
    width:100%;
    height:100%;
    border-right:1px solid gray;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
div.homeFooter>a:last-child{
    border:none;
}
div.homeHeader>div:first-child{
    margin-left:20px;
}
div.homeHeader>a{
    margin-right:30px;
    color:white;
    text-decoration: none;
    font-size:40px;
    font-weight: normal;
}

div.addEditHeader{
    width:100vw;
    height:60px;
    display:flex;
    flex-direction: row;
    align-items: center;
    color:white;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:16px;
    background-color: black;
    justify-content: space-around;
}
div.addEditHeader>a{
    color: rgb(0, 119, 255);
    text-decoration: none;;
}
div.caption{
    color:lightgray;
    width:80%;
    text-align: center;;
}
div.inputGroup{
    width:80%;
    margin-top:20px;
    display:flex;
    flex-direction: column;
    align-items: center;
}
div.inputGroup>div{
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
div.inputGroup>div>div.label{
    color:white;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    margin-bottom:5px;
    width:100%;
    text-align: center;;
}
button.info{
    background-color:transparent;
    color:lightgray;
    border:1px solid lightgray;
    height:18px;
    width:18px;
    border-radius:8px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
div.inputGroup>input{
    width:100%;
    height:32px;
    border:1px solid gray;
    background-color: black;
    color:rgb(182, 182, 182);
    font-family: 'Courier New', Courier, monospace;
    font-weight:bold;
    font-size: 20px;
    text-align: center;
}
div.inputGroup>textarea{
    width:100%;
    height:170px;
    border:1px solid gray;
    background-color: black;
    color:rgb(182, 182, 182);
    font-family: 'Courier New', Courier, monospace;
    font-weight:bold;
    font-size: 20px;
    text-align: center;
}
button.addEdit{
    width:60%;
    margin-top:20px;
    height:40px;
    font-size:16px;
    background-color: rgb(45, 45, 45);
    border:1px solid gray;
    color:rgb(255, 255, 255);
    font-weight:bold;
}
button.disabled{
    font-weight:normal;
    background-color: transparent;
    color:rgb(38, 38, 38);
}
div.options>a{
    border:1px solid gray;
    border-radius:10px;
    background-color:darkgray;
    padding:3px;
    padding-left:10px;
    padding-right:10px;
    color:black;
    font-size:14px;
}
div.options>a.selected{
    background-color: rgb(75, 75, 75);
    color:white;
   
}
div.options{
    width:90vw;
    border-left:5vw solid rgb(54, 54, 54);
    border-right:5vw solid rgb(54, 54, 54);
    border-bottom:2px solid rgb(54, 54, 54);
    border-top:2px solid gray;
    background-color: rgb(54, 54, 54);
}
textarea::placeholder,input::placeholder{
    font-size:small;
}
div.splashScreen{
    width:100%;
    height:calc(100vh - 120px);
    display:flex;
    flex-direction:column;
    align-items: center;
    background-color:white;
}
div.help{
    max-width:400px;
    overflow-y:scroll;
}
div.helpBody{
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    width:90%;
    height:auto;
}
div.helpBody>ol.contents>li{
    font-size:large;
    margin-bottom:10px;
}
div.helpBody>h1{
    margin-bottom:-10px;
    border-bottom: 1px solid black;
    width:100%;
}
div.helpBody>h2{
    margin-bottom:-10px;
    border-bottom: 1px dashed black;
    width:100%;
}
div.helpBody>h3{
    margin-bottom:-10px;

    width:100%;
}
img.small{
    max-width:300px;
}
div.splashScreen>img{
    width:100%;
    max-width:200px;
    margin:5px;
    margin-top:20px;
}
div.text,div.copyright{
    
    max-width:350px;
    height:auto;
    text-align:center;
}
div.text{
    width:80%;
}
div.copyright{
    width:100%;
    margin:15px;
}
div.motionOrient{
    color:white;
}
