#scaleBar{
    width:100vw;
    font-family:'Courier New', Courier, monospace;
    color:black;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    flex-wrap:nowrap;
    overflow:hidden;
    font-size:14px;
    height:24px;
}
#scales,#scaleVals{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
}
#scaleVals{
    margin-bottom:4px;
}
.scaleVal{
    text-align: center;
}
.scaleBar{
    border-top:1px solid black;
    border-bottom:1px solid black;
    height:5px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
}
.halfBar{
    height:5px;
    width:100%;
}