.toolTipContainer{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px)
}
.toolTip{
    width:280px;
    background-color:lightgray;
    border-radius:20px;
    border:2px solid gray;
    filter:drop-shadow(0px 0px 10px black);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:10px;
}
.toolTipHeader{
    width:90%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid black;
}
.toolTipTitle{
    font-weight:bold;
}
.toolTipMessage{
    width:90%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align:justify;
    margin-top:10px;
    margin-bottom:10px;
}
.toolTipExitButton{
    font-size: 36px;
    width:26px;
    height:26px;
    padding:0px;
    transform: rotate(45deg);
    position:relative;
    bottom: 8px;
    right:-7px;
    border:none;
    background:transparent;
}
