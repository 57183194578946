.fetchingModalContainer{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px)
}
.fetchingModal{
    width:280px;
    background-color:rgb(255, 255, 255);
    border-radius:20px;
    border:2px solid gray;
    filter:drop-shadow(0px 0px 10px black);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:10px;
}
.fetchingModalHeader{
    width:90%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.fetchingModalMessage{
    width:90%;
    font-family: 'Courier New', Courier, monospace;
    margin-top:10px;
    margin-bottom:10px;
    height:auto;
    overflow-wrap: break-word;
    word-wrap: break-word;

}
img#statusImage{
    width:60px;
    height:60px;
    margin:10px;
}
@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .fetching {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
  #fetchingModalResult{
    width:75%;
    min-height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:40px;
  }
  .resultName{
    font-weight:bold;
  }
  .resultDescription{
    margin:10px;
  }
  .resultMess{
    width:90%;
    word-wrap: break-word;
  }
  .error{color:rgb(255, 0, 0)}
  .success{color:rgb(0, 199, 43)}
button.error{
    border:2px solid red;
    border-radius: 10px;
    background-color: white;
    padding:20px;
    font-size:large;
    margin-top:20px;
    font-weight:bold;
    width:80%;
  }
 button.success{
    border:2px solid rgb(0, 255, 68);
    border-radius: 10px;
    background-color: white;
    padding:20px;
    font-size:large;
    margin-top:20px;
    font-weight:bold;
    width:80%;
  }