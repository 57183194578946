.button{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: rgba(0,0,0,0);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    cursor: pointer;
    margin: 3px;
    margin-right: -3px;
}
.active{
    opacity:0.7;
    border: 1px solid rgb(182, 182, 182);
}
.inactive{
    opacity:0.4;
    border: 1px solid rgb(255, 255, 255);
}
.inactive.Survey{
    opacity:0.2;
}
.missing{
    opacity:0.1;
    cursor:not-allowed;
    pointer-events: none;
}
button.open{
    border: 1px solid rgb(182, 182, 182);
    opacity:0.7;
    background-color:rgb(160, 160, 160);
}
button.closed{
    border: 1px solid rgb(182, 182, 182);
    opacity:0.7;
    background-color:rgba(0, 0, 0, 0);
}
button.expander{
    width:32px;
    height:22px;
    margin-right:3px;
    border:none;
    background-image:url('../components/Buttons/expanderButton/closedIcon.svg');
    background-size:50%;
    border-left: 1px solid gray;
    border-radius:0;
}
button.expanded{
    background-image:url('../components/Buttons/expanderButton/openIcon.svg');
    background-size:40%;
}
.layerIcon,.focusIcon{
    width:22px;
    height:22px;
    margin-right:6px;
    border:none;
    background-size:70%;  
}
.visible{
    background-image:url('../components/Buttons/VisibleButton/visibleIcon.svg');
    
}
.invisible{
    background-image:url('../components/Buttons/VisibleButton/invisibleIcon.svg');
    opacity:0.3;
    background-size:78%;
}
.focusIcon{
    background-image:url("../assets/icons/Focus.svg");
    background-size:80%;
    opacity:0.8;
}
.Survey{
    background-image:url("../assets/icons/Survey.svg");
    background-size:100%;
}
.Details{
    background-image:url("../assets/icons/Details.svg");
    background-size:100%;
}
.Ceiling{
    background-image:url("../assets/icons/Ceilings.svg");
    background-size:100%;
}
.Drops{
    background-image:url("../assets/icons/Drops.svg");
    background-size:100%;
    border-radius:0px;
}
.Text{
    background-image:url("../assets/icons/Text.svg");
    background-size:70%;
}
.Leads{
    background-image:url("../assets/icons/Leads.svg");
    background-size:30%;
}
.text{
    background-image:none;
    color:white;
    width:auto;
}