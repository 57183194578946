#layersSubMenuButton{
    background-image: url('layers.svg');
}
#titleSubMenuButton{
    background-image: url('title.svg');
}
#waypointsSubMenuButton{
    background-image: url('waypoints.svg');
    background-size: 50%;
}
#underlayersSubMenuButton{
    background-image: url('underlayers.svg');
    background-size: 90%;
    opacity:1;
    border-color:rgb(156, 156, 156);
}