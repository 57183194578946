.navBar{
    background-color: rgb(88,88,88);
    display:flex;
    flex-direction:row;
    align-items: center;
    width:100vw;
    height:50px;
}
#zoomControls{
    justify-content: center;
}
#zoomControls>button{
    margin-left:1px;
    margin-right:1px;
    margin-bottom:10px;
}
#topControls{
    justify-content: space-evenly;
}