#majorGridContainer{
    width:100vw;
    height:calc(100vh - 144px);
    position:fixed;
    top:70px;
    left:0;
    overflow:hidden;
    overflow:hidden;
    flex-wrap:nowrap;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    z-index:0;
}
#minorGridContainer{
    width:100vw;
    height:calc(100vh - 144px);
    position:fixed;
    top:70px;
    left:0;
    overflow:hidden;
    overflow:hidden;
    flex-wrap:nowrap;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    z-index:0;
}
