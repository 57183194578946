#modalContainer{
    position:fixed;
    bottom:0px;
    left:0;
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
   

}
.fullScreenModal{
    width:100vw;
    height:100vh;
    background-color:rgb(255, 255, 255);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    opacity:1;
    transition:opacity 0.5s;
    position:fixed;
    top:0;
    left:0;
    z-index:200;
}
.startPositionModal{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:auto;
    height:auto;
    background-color: white;
    border:2px solid rgb(201, 201, 201);
    padding:10px;
    border-radius:10px;
    pointer-events: all;
    animation-name:startmodalappear;
    animation-duration: 500ms;
}
@keyframes startmodalappear{
    0% {opacity:0}
    100% {opacity:1}
}
.startPositionModal>div:first-child{
    font-size:small;
    border-bottom:1px solid gray;
    padding:4px;
    margin-top:-4px;
}
.modalSelector{
    min-width:80%;
    border:1px solid rgb(229, 229, 229);
    border-radius:2px;
    margin-top:4px;
    padding:2px;
    font-size:small;
    background-color: lightgray;
    cursor:pointer;
}
.modalSelector>div:nth-child(2){
    font-size:x-small;
    font-style:italic;
}
.loadingModal{
    width:300px;
    height:500px;
    background-color: white;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    pointer-events: all;
    opacity:1;
    transition:opacity 0.5s;
}
.loadingLogo{
    width:100%;
}
#loadingLogContainer{
    width:100%;
    height:100%;
    border-bottom:1px solid gray;
    overflow-y:scroll;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
#loadingLog{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column-reverse;
}
.loadingNote{
    display:flex;
    width:100%;
    max-width:50vw;
    text-align:left;
    font-size:small;
    font-family: 'Courier New', Courier, monospace;
    text-wrap:wrap;
    height:auto;
    word-break: break-all;
}
.loadingNote.subnote{
    font-size:x-small;
}
.loadingNote.error{
    border:none;
    filter:none;
}
.loadingNote.success{
    border:none;
    filter:none;
    font-weight:bold;
    color:rgb(36, 166, 36);
}
.loadingNote.warning{
    border:none;
    filter:none;
    font-weight:bold;
    color:rgb(166, 166, 36);
}