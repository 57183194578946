.subMenu{
    position:fixed;
    top:48px;
    left:0;
    width:100vw;
    height:0px;
    overflow-x:hidden;
    overflow-y:scroll;
    background-color:rgb(182, 182, 182);
    filter: drop-shadow(0px 20px 10px #00000052);
    z-index:30;
}
.subMenuContents{
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
    width:100vw;
    height:auto;
}
div#subMenu_title{

    background-color:rgb(222, 222, 222);


}
.subMenuMenu{
    display:flex;
    flex-direction:column;
    justify-content:start;
    flex-wrap:nowrap;
    width:100%;
    height:30px;
    border-bottom:1px solid gray;
    overflow:hidden;
}
div#subMenu_layers{
    display:flex;
    flex-direction: column-reverse;
}
div.open{
   
    height:auto;
    max-height:80vh;
}
.titleBlock_title{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size:larger;
    margin-top:4px;
    text-decoration:underline;
}
.titleBlock_location,.titleBlock_date{
    font-size:small;
    width:50%;
}
.titleBlock_date{margin-top:4px;}
.titleBlock_version{
    font-size:x-small;
}
.titleBlock_lede{
    display:flex;
    flex-direction:row;

    font-size:small;
}
.top{
    margin-top:10px;
}

.lede{
    font-weight:bold;
    margin-right:10px;
}
.titleBlock_row{
    display:flex;
    flex-direction:row;
    margin-top:10px;
    font-size:small;
}
.titleBlock_surveyHeader,.titleBlock_geology{
    width:50%;
    font-size:small;
    margin-top:10px;
    margin-bottom:5px;
}
.titleBlock_surveyor{
    font-size:small;
}
.titleBlock_surveyors{
    display:flex;
    flex-direction:column;
    flex-wrap:nowrap;
    width:50%;
    min-height:100px;
    height:auto;
    max-height:100px;
    overflow-y:scroll;
    background-color:rgb(222, 222, 222);
    border-radius:10px;
}
.titleBlock_dataStr{
    font-size: xx-small;
    font-family:'Courier New', Courier, monospace;
    width:50vw;
    overflow-wrap: break-word;
    text-wrap:wrap;
    margin-top:20px;
    margin-bottom:20px;
}
.titleBlock_copyright{
    font-size: smaller;
    width:50vw;

    text-wrap:wrap;
    margin-top:20px;
}

div.expandedMenu>div{
margin-top:-2px;
}
div#subMenu_layers>div.expandedMenu{
    height:72px;
}
div.expandedMenu{
    height:auto;
}
div.subMenuHeader{
    background-color:rgb(124, 124, 124);
    display:flex;
    flex-direction:row;
    justify-content:start;
    align-items:center;
    color:white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:medium;
    width:100%;
    height:32px;
    cursor:pointer;
}
div#subMenu_layers>div.subMenuMenu>div>div{
    width:100%;
    text-align:left;
}
div.subMenuLine{
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    min-height:25px;
    background-color:rgb(88,88,88);
    color:white;
    font-family:'Courier New', Courier, monospace;
    cursor:pointer;
    border-bottom:1px dashed gray;
}
div#subMenu_layers>div.subMenuMenu>div.subMenuLine{
    height:44px;
    justify-content:space-around;
    cursor:unset;
}
.subMenuHeaderNote{
    font-style:italic;
    color:rgb(195, 195, 195);
    margin-left:10px;
}
div.subMenuList{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    width:100%;
    height:auto;
}
div.subMenuLine>div{
    font-size: small;
}
.buffer{
    width:30px;
}
div.subMenuMenu>div.subMenuList>div.subMenuMenu>div.subMenuHeader{
    background-color:rgb(71, 71, 71);
}
.searchButton,.closeSearchBar{
    
    background-color:rgb(59, 59, 59);
    background-size:50%;
    background-repeat:no-repeat;
    background-position: center;
    opacity:0.8;
    width:32px;
    height:32px;
    border:none;
}
.searchButton{
    background-image: url('../../assets/icons/Search.svg');
}
.closeSearchBar{
    background-image: url('../../assets/icons/Close.svg');
    display:none;
    visibility:hidden;
}
.display{
    visibility:unset;
    display:unset;
}
#searchBar{
    height:32px;
}
.searchInput{
    margin:0px;
    width:100%;
    height:100%;
    color:white;
    background-color:rgb(71, 71, 71);
    border:none;
}
.searchInput::placeholder{
    color:lightgray;
}

div#searchContainer.focused{
    overflow:visible;
    z-index:300;
    display:flex;
    flex-direction:column;
}
div#searchResults.focused{
    display:block;
    width:100vw;
    min-height:80vh;
    border-top:1px dashed gray;
    background-color:rgb(71, 71, 71);
    overflow-y:scroll;
}
#resultsContainer{
    display:flex;
    flex-direction:column;
    align-items:left;
    justify-content: flex-start;
    color:lightgray;
    font-family: 'Courier New', Courier, monospace;
    width:100vw;
    height:auto;
    margin-top:5px;
}
.searchReturn{
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    height:25px;
    color:white;
    font-family:'Courier New', Courier, monospace;
    cursor:pointer;
    font-size:small;
    border-bottom:1px dotted gray;
}
.hideAway{
    display:none;
    visibility:hidden;
}
.full{
    min-height:50vh;
}