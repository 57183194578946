div.areYouSureModalContainer{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px)
}
div.areYouSureModal{
    width:280px;
    background-color:lightgray;
    border-radius:20px;
    border:2px solid gray;
    filter:drop-shadow(0px 0px 10px black);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:10px;
}
.areYouSureModal>div{
    width:90%;
    text-align:center;
    margin:10px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.areYouSureModal>div>button{
    width:100%;
    text-align:center;
    margin:10px;
    height:40px;
   
    border-radius:10px;
  
    font-size:larger;
    font-weight:bold;
}
button.yes{
    border: 2px solid red;
    color:red;
    background-color:pink;
}
button.no{
    border: 2px solid green;
    color:green;
    background-color:rgb(192, 255, 221);
}
