
.in{
background-image: url("zoomIn.svg");
}
.out{
    background-image: url("zoomOut.svg");
}
.one{
    background-image: url("zoomOne.svg");
}
.fix{
    background-image: url("zoomFix.svg");
}
